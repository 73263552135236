<template>
  <div>
    <p style="display: none">{{pauseSetting}}</p>
    <b-card title="Add Pause Setting">
      <b-row class="py-0" align-h="center">
        <b-col sm="3"> </b-col>
        <b-col sm="9">
          <p>Set Time</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p>1. Daylight Logout</p>
        </b-col>
        <b-col sm="9">
          <b-form-timepicker
            v-model="dayTimeLogout"
            locale="en"
          ></b-form-timepicker>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col sm="3">
          <p>2. Daynight Logout</p>
        </b-col>
        <b-col sm="9">
          <b-form-timepicker
            v-model="nightTimeLogout"
            locale="en"
          ></b-form-timepicker>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col sm="3">
          <p>3. Interval Idle Time (in Minute)</p>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="intervalIdleTime"
            v-model="intervalIdleTime"
            required
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row align-h="end" class="pt-3">
        <b-button variant="primary" class="mr-5" @click="updatePauseSetting()"
          >Update Pause Settings</b-button
        >
      </b-row>
    </b-card>
    <b-card title="Pause Job List">
      <b-col cols="12">
        <b-row style="margin-bottom:15px;">
          <b-col cols="4" />
          <b-col cols="4" />
          <b-col cols="4">
            <div>
              <b-row>
                <span class="spin" v-if="showSpinner"></span>
              </b-row>
              <b-form-input
                v-model="search"
                input="debounceSearch"
                type="search"
                placeholder="Type to Search"
                style="float: right;"
              />
            </div>
          </b-col>
        </b-row>

        <b-tabs>
          <b-tab title="All Job" active>
            <b-row>
              <b-col cols="12">
                <div style="overflow-x: visible;">
                  <b-table
                    :filter="search"
                    hover
                    responsive
                    small
                    head-variant="dark"
                    outlined
                    :items="pausejobs"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :per-page="perPage"
                    :current-page="currentPage"
                  >
                  </b-table>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4" class="pt-1">
                <b-form-group
                  label="Data Per Page"
                  label-for="per-page-select"
                  label-cols-md="0"
                  label-align-sm="left"
                  label-size="md"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col class="pt-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="float-right"
                  aria-controls="user-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/utils.js";
export default {
  data() {
    return {
      fields: [
        {
          key: "user.username",
          label: "Username",
          filterable: true,
          sortable: true,
        },
        {
          key: "user.email",
          label: "Email",
          filterable: true,
          sortable: true,
        },
        {
          key: "pauseTimestamp",
          label: "Pause Time",
          filterable: true,
          sortable: true,
          formatter: value => {
              return dateFormat(value)
            }
        },
        {
          key: "pauseInterval",
          label: "Interval Time",
          filterable: true,
          sortable: true,
          formatter: value => {
              return value + ' minutes'
            }
        },
        {
          key: "restartTimestamp",
          label: "Restart Time",
          filterable: true,
          sortable: true,
          formatter: value => {
              return dateFormat(value)
            }
        },
      ],
      sortBy: "",
      sortDesc: false,
      currentPage: 1,
      perPage: 5,
      search: null,
      pageOptions: [5, 10, 20, 100],
      dayTimeLogout: "",
      nightTimeLogout: "",
      intervalIdleTime: "",
      showSpinner: false,
      defaultIdleInterval: 5,
    };
  },
  methods: {
    updatePauseSetting() {
      var pauseSet = {
        logoutSessionMorning: this.dayTimeLogout,
        logoutSessionNight: this.nightTimeLogout,
        intervalTime: this.intervalIdleTime,
      };
      this.$store
        .dispatch("pause/updatePauseJobSet", {
          id: this.pauseSetting.id,
          payload: pauseSet,
        })
        .then((x) => {
          this.$bvToast.toast("PauseJob Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },

    dateFormat(date) {
      return new Date(date);
    },
    // add spinner while loading on searching data process
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event.target.value;
      }, 600);
    },
  },
  computed: {
    pauseSetting() {
      let pauseSetting = this.$store.getters["pause/getPauseSetting"];
      if (pauseSetting.length == 1){
        this.dayTimeLogout = pauseSetting[0]?.logoutSessionMorning
        this.nightTimeLogout = pauseSetting[0]?.logoutSessionNight
        this.intervalIdleTime = pauseSetting[0]?.intervalTime
      }else  {
        var defaultData = {};
        this.$store
          .dispatch("pause/addPauseJobSetting", defaultData)
          .then((res) => {
            this.dayTimeLogout = "08:00";
            this.nightTimeLogout = "20:00";
            this.intervalIdleTime = this.defaultIdleInterval;
          });
      }
      // console.log(pauseSetting[0]);
      return pauseSetting[0];
    },
    rows() {
      return this.pausejobs.length;
    },
    pausejobs() {
      // var data = this.$store.getters["pause/getPauseJobs"].filter(
      //   (x) => (
      //     (x.pauseTimestamp = dateFormat(x.pauseTimestamp)),
      //     (x.restartTimestamp = dateFormat(x.restartTimestamp)),
      //     (x.pauseInterval = x.pauseInterval+ ' minutes')
      //   )
      // ).map(el => {
      //   var newCreated = (el.pauseTimestamp).split(",")
      //   var newUpdated = (el.restartTimestamp).split(",")
      //   return {
      //           ...el,
      //           pauseTimestamp: newCreated[0] + newCreated[1].replace(/\./g, ':'),
      //           restartTimestamp: newUpdated[0] + newUpdated[1].replace(/\./g, ':'),
      //         };
      // });
      // console.log("a",data);
      // return data;
      return this.$store.getters["pause/getPauseJobs"]
    },
  },
  mounted() {
    this.$store.dispatch("pause/getPauseJobs");
    this.$store.dispatch("pause/getPauseJobSetting")

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPagePauseSettingManufacture")) {
      this.perPage = this.$session.get("perPagePauseSettingManufacture");
    }
    if (this.$session.has("sortByPauseSettingManufacture")) {
      this.sortBy = this.$session.get("sortByPauseSettingManufacture");
    }
    if (this.$session.has("sortDescPauseSettingManufacture")) {
      this.sortDesc = this.$session.get("sortDescPauseSettingManufacture");
    }
  },
  watch: {
      // Taking the Menu Setting from localstorage session so the setting will be the same as before
      perPage(perPageNew) {
        this.$session.set("perPagePauseSettingManufacture", perPageNew);
      },
      sortBy(sortByNew) {
        this.$session.set("sortByPauseSettingManufacture", sortByNew);
      },
      sortDesc(sortDescNew) {
        this.$session.set("sortDescPauseSettingManufacture", sortDescNew);
      },
      // stackedStatus(stackedStatusNew) {
      // this.$session.set("stackedStatusJoborderManufacture", stackedStatusNew)
      // }
    },
};
</script>
